import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '@/components/Layout';
import BlogContent from '@/components/BlogContent';

const Blog: FC<PageProps<DataType>> = ({
  data: {
    allMarkdownRemark: { nodes, group },
  },
}) => (
  <Layout>
    <BlogContent
      posts={nodes.map((node) => ({
        id: node.id,
        title: node.frontmatter.title,
        desc: ``,
        path: node.fields.slug,
        date: node.frontmatter.date,
      }))}
      categoryList={group.map((item) => ({
        value: item.fieldValue,
        count: item.totalCount,
      }))}
    />
  </Layout>
);

export default Blog;

export const query = graphql`
  query GetPosts {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "yyyy년 M월 D일", locale: "ko")
        }
        id
      }
      group(field: frontmatter___category) {
        totalCount
        fieldValue
      }
    }
  }
`;

type DataType = {
  allMarkdownRemark: {
    nodes: {
      fields: {
        slug: string;
      };
      frontmatter: {
        title: string;
        date: string;
      };
      id: string;
    }[];
    group: { totalCount: number; fieldValue: string }[];
  };
};
