import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.aside`
  width: 200px;
  padding: 20px;
`;

const CategoryItemSection = styled.section`
  margin-top: 15px;
`;

const Item = styled.div`
  font-size: 0.8rem;
`;

const Category = styled.p`
  margin: 0;
`;

const CategoryList: FC<{
  categoryList: CategoryType[];
  className?: string;
}> = ({ categoryList, className }) => (
  <Wrapper className={className}>
    <section>
      <strong>categories</strong>
    </section>
    <CategoryItemSection>
      {categoryList.map((category) => (
        <Item key={category.value}>
          <Category>
            <Link to={`/category/${category.value}`}>
              {`${category.value} (${category.count})`}
            </Link>
          </Category>
        </Item>
      ))}
    </CategoryItemSection>
  </Wrapper>
);

export default CategoryList;
