import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ItemWrapper = styled.div``;

const Date = styled.div`
  font-size: 0.7rem;
  color: #7f8c8d;
`;

const Title = styled.strong`
  font-size: 1.7rem;
`;

const Desc = styled.p``;

const PostSummary: FC<{ post: PostSummaryType; className?: string }> = ({
  post: { title, desc, path, date },
  className,
}) => (
  <ItemWrapper className={className}>
    <Link to={path}>
      <Date>{date}</Date>
      <Title>{title}</Title>
      <Desc>{desc}</Desc>
    </Link>
  </ItemWrapper>
);

export default PostSummary;
