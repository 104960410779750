import React, { FC } from 'react';

import Layout from '@/components/Layout';
import Intro from '@/components/Intro';

const Algorithm: FC = () => (
  <Layout>
    <Intro />
  </Layout>
);

export default Algorithm;
