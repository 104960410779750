import React, { FC } from 'react';
import { PageProps, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import { widthRuleMixin } from '@/constants/styles';

const Article = styled.article`
  padding-top: 75px;
`;

const Content = styled.div`
  background-color: white;
  padding: 1px 30px 30px;
  ${widthRuleMixin}
`;

const ContentHeader = styled.section`
  margin-bottom: 50px;
`;

const Date = styled.div`
  font-size: 0.8rem;
  color: #7f8c8d;
`;

const ContentBody = styled.section``;

const BlogPost: FC<PageProps<DataType>> = ({
  data: {
    markdownRemark: { html, frontmatter },
  },
}) => (
  <Layout>
    <Article>
      <Content>
        <ContentHeader>
          <h1>{frontmatter.title}</h1>
          <Date>{frontmatter.date}</Date>
        </ContentHeader>
        <ContentBody>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </ContentBody>
      </Content>
    </Article>
  </Layout>
);

export default BlogPost;

type DataType = {
  markdownRemark: {
    html: string;
    frontmatter: {
      date: string;
      title: string;
      featuredImage?: {
        childImageSharp: {
          fluid: FluidObject;
        };
      };
    };
  };
};

export const query = graphql`
  query GetPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "yyyy년 M월 D일", locale: "ko")
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
