import React, { FC } from 'react';
import styled from 'styled-components';
import { graphql, PageProps } from 'gatsby';

import Layout from '@/components/Layout';
import BlogContent from '@/components/BlogContent';
import { widthRuleMixin } from '@/constants/styles';

const CategoryTitle = styled.h1`
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 150px;
  ${widthRuleMixin}
`;

const Blog: FC<PageProps<DataType, ContextType>> = ({
  data: {
    posts: { nodes },
    categoryList: { group },
  },
  pageContext: { category },
}) => (
  <Layout>
    <CategoryTitle>{category}</CategoryTitle>
    <BlogContent
      posts={nodes.map((node) => ({
        id: node.id,
        title: node.frontmatter.title,
        desc: ``,
        path: node.fields.slug,
        date: node.frontmatter.date,
      }))}
      categoryList={group.map((item) => ({
        value: item.fieldValue,
        count: item.totalCount,
      }))}
    />
  </Layout>
);

export default Blog;

export const query = graphql`
  query CategoryPostsQuery($category: String!) {
    posts: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "yyyy년 MMM d일", locale: "ko")
        }
        id
      }
    }
    categoryList: allMarkdownRemark {
      group(field: frontmatter___category) {
        totalCount
        fieldValue
      }
    }
  }
`;

type DataType = {
  posts: {
    nodes: {
      fields: {
        slug: string;
      };
      frontmatter: {
        title: string;
        date: string;
      };
      id: string;
    }[];
  };
  categoryList: {
    group: {
      totalCount: number;
      fieldValue: string;
    }[];
  };
};

type ContextType = {
  category: string;
};
