import React, { FC } from 'react';
import styled from 'styled-components';

import PostSummary from '@/components/PostSummary';
import CategoryList from '@/components/CategoryList';
import { widthRuleMixin } from '@/constants/styles';

const Content = styled.div`
  padding-top: 75px;
  position: relative;
  display: flex;
  justify-content: center;
  height: 1000px;

  ${widthRuleMixin}
`;

const PostSummaryWrapper = styled.div`
  width: 70%;
  background-color: white;
`;

const StyledPostSummary = styled(PostSummary)`
  padding: 30px 0;
  margin: 0 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #dcdde1;
  }
`;

const StyledCategoryList = styled(CategoryList)`
  margin-left: 15px;
  background-color: white;
`;

const BlogContent: FC<{
  posts: PostSummaryType[];
  categoryList: CategoryType[];
}> = ({ posts, categoryList }) => (
  <Content>
    <PostSummaryWrapper>
      {posts.map((post) => (
        <StyledPostSummary key={post.id} post={post} />
      ))}
    </PostSummaryWrapper>
    <StyledCategoryList categoryList={categoryList} />
  </Content>
);

export default BlogContent;
