import React, { FC } from 'react';
import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';

import { widthRuleMixin } from '@/constants/styles';

const Title = styled.h2`
  color: white;
  padding-left: 30px;
  z-index: 1;
`;

const Desc = styled.p`
  z-index: 1;
`;

const BackgroundWrapper = styled.div`
  position: relative;
  height: 450px;
  background-color: black;
`;

const ForeGroundWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  ${widthRuleMixin}
`;

const Intro: FC<{
  title?: string;
  desc?: string;
  featuredImage?: FluidObject;
}> = ({ title, desc, featuredImage }) => (
  <BackgroundWrapper>
    <ForeGroundWrapper>
      {Boolean(featuredImage) && (
        <Img
          fluid={featuredImage}
          style={{
            position: `absolute`,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      )}
      {Boolean(title) && <Title>{title}</Title>}
      {Boolean(desc) && <Desc>{desc}</Desc>}
    </ForeGroundWrapper>
  </BackgroundWrapper>
);

export default Intro;
